<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Total Histogram" name="totalHistoryHistogram" :lazy="false">
    </el-tab-pane>
    <el-tab-pane
      label="Foundation Histogram"
      name="foundationHistoryHistogram"
      :lazy="false"
    >
    </el-tab-pane>
    <el-tab-pane
      label="Development Histogram"
      name="developmentHistoryHistogram"
      :lazy="false"
    >
    </el-tab-pane>
    <el-tab-pane
      label="Enrichment Histogram"
      name="enrichmentHistoryHistogram"
      :lazy="false"
    >
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { getData } from "../../service/api";
import { Chart, registerables } from "chart.js";

export default {
  data() {
    return {
      controllerUrl: "/testReport",
      query: {
        studentId: null,
      },
      styleConfig: {
        line: {
          total: {
            font: "bold 12px Arial",
            borderColor: "#233c64",
            bgColor: "#233c64",
            pointColor: "#233c64",
          },
          foundation: {
            font: "bold 12px Arial",
            borderColor: "#233c64",
            bgColor: "#233c64",
            pointColor: "#233c64",
          },
          development: {
            font: "bold 12px Arial",
            borderColor: "#233c64",
            bgColor: "#233c64",
            pointColor: "#233c64",
          },
          enrichment: {
            font: "bold 12px Arial",
            borderColor: "#233c64",
            bgColor: "#233c64",
            pointColor: "#233c64",
          },
        },
        avgLine: {
          total: {
            font: "bold 12px Arial",
            borderColor: "#cccccc",
            bgColor: "#cccccc",
            pointColor: "#cccccc",
          },
          foundation: {
            font: "bold 12px Arial",
            borderColor: "#cccccc",
            bgColor: "#cccccc",
            pointColor: "#cccccc",
          },
          development: {
            font: "bold 12px Arial",
            borderColor: "#cccccc",
            bgColor: "#cccccc",
            pointColor: "#cccccc",
          },
          enrichment: {
            font: "bold 12px Arial",
            borderColor: "#cccccc",
            bgColor: "#cccccc",
            pointColor: "#cccccc",
          },
        },
        medianLine: {
          total: {
            font: "bold 12px Arial",
            borderColor: "#55bae7",
            bgColor: "#55bae7",
            pointColor: "#55bae7",
          },
          foundation: {
            font: "bold 12px Arial",
            borderColor: "#55bae7",
            bgColor: "#55bae7",
            pointColor: "#55bae7",
          },
          development: {
            font: "bold 12px Arial",
            borderColor: "#55bae7",
            bgColor: "#55bae7",
            pointColor: "#55bae7",
          },
          enrichment: {
            font: "bold 12px Arial",
            borderColor: "#55bae7",
            bgColor: "#55bae7",
            pointColor: "#55bae7",
          },
        },
      },
      activeName: "totalHistoryHistogram",
      chartList: [],
      studentRecordList: [],
      avgRecordList: [],
      medianRecordList: [],
      studentRecordDic: {},
      avgRecordDic: {},
      medianRecordDic: {},
    };
  },
  created() {
    Chart.register(...registerables);
    let studentId = this.$user.getUsername();
    if (studentId) {
      this.initAndLoadData(studentId);
    }
  },
  mounted() {},
  methods: {
    initAndLoadData(studentId) {
      this.query.studentId = studentId;
      this.loadData();
    },
    async loadData() {
      let url = `${this.controllerUrl}/getStudentRecordHistory/${this.query.studentId}`;
      let res = await getData(url);
      if (res.result && res.code === "200") {
        this.studentRecordList = res.result.studentTestRecordList;
        this.recordListInAvgAndMedianList =
          res.result.testRecordInAverageAndMedianList;
        let labelList = [0];
        for (let i = 0; i < this.studentRecordList.length; i++) {
          let record = this.studentRecordList[i];
          labelList.push(`${record.grade}/${record.term}`);
        }
        for (let recordType in this.styleConfig.line) {
          let list1 = [0];
          let list2 = [0];
          let list3 = [0];

          for (let i = 0; i < this.studentRecordList.length; i++) {
            let record = this.studentRecordList[i];
            list1.push(record[recordType]);
          }
          this.studentRecordDic[recordType] = list1;

          for (let i = 0; i < this.recordListInAvgAndMedianList.length; i++) {
            let record = this.recordListInAvgAndMedianList[i];
            list2.push(record[`${recordType}Average`]);
            list3.push(record[`${recordType}Median`]);
          }
          this.avgRecordDic[recordType] = list2;
          this.medianRecordDic[recordType] = list3;
        }
        console.log(
          "loadData",
          this.studentRecordList,
          this.recordListInAvgAndMedianList,
          labelList,
          this.studentRecordDic,
          this.avgRecordDic,
          this.medianRecordDic
        );
        this.chartList = [];
        for (let recordType in this.styleConfig.line) {
          let styleObj = this.styleConfig.line[recordType];
          let avgStyleObj = this.styleConfig.avgLine[recordType];
          let medianStyleObj = this.styleConfig.medianLine[recordType];
          let parentNode = document.getElementById(
            `pane-${recordType}HistoryHistogram`
          );
          parentNode.innerHTML = "";
          this.renderChart(
            parentNode,
            recordType,
            labelList,
            this.studentRecordDic[recordType],
            this.avgRecordDic[recordType],
            this.medianRecordDic[recordType],
            styleObj,
            avgStyleObj,
            medianStyleObj
          );
        }
      }
    },
    renderChart(
      parentNode,
      recordType,
      labelList,
      dataList,
      avgDataList,
      medianDataList,
      styleObj,
      avgStyleObj,
      medianStyleObj
    ) {
      console.log(
        "renderChart",
        dataList,
        avgDataList,
        medianDataList,
        styleObj,
        avgStyleObj,
        medianStyleObj
      );

      let ctx = document.createElement("canvas");
      let div = document.createElement("div");
      div.appendChild(ctx);
      parentNode.appendChild(div);

      let chart = new Chart(ctx, {
        type: "line",
        options: {
          plugins: {
            title: {
              display: true,
              position: "top",
              text: "My Score Trendline",
            },
          },
          scales: {
            y: {
              min: 0,
            },
          },
        },
        data: {
          labels: labelList,
          datasets: [
            {
              label: "My Grades",
              data: dataList,
              borderColor: styleObj.borderColor,
              backgroundColor: styleObj.bgColor,
              pointBackgroundColor: styleObj.pointColor,
              pointBorderColor: styleObj.pointColor,
              pointHoverBackgroundColor: styleObj.pointColor,
              pointHoverBorderColor: styleObj.pointColor,
              tension: 0.1,
              borderWidth: 1,
            },
            {
              label: "Median Grades",
              data: medianDataList,
              borderDash: [5, 5],
              borderColor: medianStyleObj.borderColor,
              backgroundColor: medianStyleObj.bgColor,
              pointBackgroundColor: medianStyleObj.pointColor,
              pointBorderColor: medianStyleObj.pointColor,
              pointHoverBackgroundColor: medianStyleObj.pointColor,
              pointHoverBorderColor: medianStyleObj.pointColor,
              tension: 0.1,
              borderWidth: 1,
            },
          ],
        },
      });
      chart["chartState"] = {
        othis: this,
        recordType: recordType,
        labelList: labelList,
        dataList: dataList,
      };

      this.chartList.push(chart);
    },
  },
};
</script>
