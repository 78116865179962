<template>
  <div class="dashboard">
    <table class="dtable">
      <tr>
        <td><student-test-record-history-chart /></td>
        <td
          title="click to refresh"
          class="points"
          @click="loadStudentPoints(false)"
        >
          My Current Points {{
            this.pointsData.points +
              this.pointsData.pendingPoints +
              this.pointsData.creditPoints
          }}
          <el-icon v-if="loadingStudentPoints" class="is-loading">
            <loading />
          </el-icon>
        </td>
      </tr>
      <tr>
        <td>
          <h2 v-if="showCurrentReport" class="subject">
            Your latest report for {{ this.currentReport.grade }},
            {{ this.currentReport.term }}
          </h2>
          <test-report-chart
            v-if="showCurrentReport"
            :isForAdmin="false"
            :reportId="currentReport.reportId"
            :studentId="query.studentId"
            :destroy-on-close="true"
          />
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>
import { getData } from "../../service/api";
import StudentTestRecordHistoryChart from "../common/StudentTestRecordHistoryChart.vue";
import TestReportChart from "../common/TestReportChart.vue";

export default {
  components: { StudentTestRecordHistoryChart, TestReportChart },

  name: "dashboard",
  data() {
    return {
      query: {
        studentId: null,
      },
      showCurrentReport: false,
      loadingStudentPoints: false,
      pointsData: {},
      currentReport: {},
    };
  },
  created() {
    let studentId = this.$user.getUsername();
    if (studentId) {
      this.query.studentId = studentId;
      this.initAndLoadData();
    }
  },
  computed: {},
  methods: {
    initAndLoadData() {
      this.loadStudentPoints();
      this.getStudentCurrentReport();
    },
    loadStudentPoints() {
      let showGlobalLoading = true;
      if (arguments.length > 0) {
        showGlobalLoading = arguments[0];
      }
      this.loadingStudentPoints = true;
      getData(`points/student/${this.query.studentId}`, null, {
        isShowLoading: showGlobalLoading,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.pointsData = res.result;
          this.loadingStudentPoints = false;
        }
      });
    },
    getStudentCurrentReport() {
      getData(`testReport/getCurrentTestReportForStudent`, null, {
        isShowLoading: false,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.currentReport = res.result;
          this.showCurrentReport = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.dashboard .subject {
  height: 38px;
  line-height: 38px;
  color: #3f9dff;
  text-align: center;
  border-bottom: solid 2px #e4e7ed;
  font-weight: 400;
}
.dashboard .dtable {
  display: block;
  min-width: 100%;
  word-wrap: break-word;
  display: table;
}
.dashboard table.dtable tr {
  min-width: 100%;
}
.dashboard table.dtable tr td {
  padding-top: 20px;
  vertical-align: top;
  width: 50%;
  word-wrap: break-word;
}
.dashboard table.dtable tr td.points {
  font-weight: 600;
  font-size: 40px;
  color: red;
  background-color: #eeeeee;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
</style>
